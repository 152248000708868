<template>
<v-container fluid fill-height v-if="show">
    <template v-if="!$route.params.method">
        <UserAPIservicesPage />
    </template>

    <v-breadcrumbs :items="breadItems" v-if="$route.params.method" class="pl-0 py-4 pt-2 align-self-start">
        <template v-slot:divider>
            <v-icon>mdi-arrow-right</v-icon>
        </template>
    </v-breadcrumbs>

    <template v-if="$route.params && $route.params.method=='get-auth-token'">
        <UserAPIAuth />
    </template>
    <template v-if="$route.params && $route.params.method=='get-price'">
        <UserAPIPrice />
    </template>
    <template v-if="$route.params && $route.params.method=='get-price-ws'">
        <UserAPIPriceWS />
    </template>
    <template v-if="$route.params && $route.params.method=='get-content-goods'">
        <UserAPIContentGoods />
    </template>
    <template v-if="universalMethod">
        <UserAPIUniversal v-if="$route.params.method" />
    </template>
</v-container>
</template>

<script>
import UserAPIservicesPage from '../components/API_services/UserAPI_servicesPage.vue'
import {
    mapActions
} from 'vuex'

export default {
    components: {
        UserAPIservicesPage,
        UserAPIAuth: () => import('../components/API_services/methods/UserAPI_Auth.vue'),
        UserAPIPrice: () => import('../components/API_services/methods/UserAPI_Price.vue'),
        UserAPIPriceWS: () => import('../components/API_services/methods/UserAPI_PriceWS.vue'),
        UserAPIContentGoods: () => import('../components/API_services/methods/UserAPI_ContentGoods.vue'),
        UserAPIUniversal: () => import('../components/API_services/methods/UserAPI_Universal.vue'),
    },
    data: () => ({
        methods: [{
                name: 'get-auth-token',
                rules: [],
                chapter: 'Авторизація'
            }, {
                name: 'get-price',
                rules: [],
                chapter: 'Прайс-листи'
            }, {
                name: 'get-price-ws',
                rules: [],
                chapter: 'Прайс-листи'
            }, {
                name: 'post-order',
                rules: [],
                chapter: 'Замовлення',
                universal: true
            }, {
                name: 'get-order-sn',
                rules: [],
                chapter: 'Звіти',
                universal: true
            }, {
                name: 'get-doc-sn',
                rules: [],
                chapter: 'Звіти',
                universal: true
            }, {
                name: 'delete-order',
                rules: [],
                chapter: 'Замовлення',
                universal: true
            }, {
                name: 'confirm-order',
                rules: [],
                chapter: 'Замовлення',
                universal: true
            }, {
                name: 'get-order-info',
                rules: [],
                chapter: 'Звіти',
                universal: true
            }, {
                name: 'get-time',
                rules: [],
                chapter: 'Авторизація',
                universal: true
            },
            {
                name: 'get-sales-goods',
                rules: [],
                chapter: 'Звіти',
                universal: true
            },
            {
                name: 'get-sales',
                rules: [],
                chapter: 'Звіти',
                universal: true
            },
            {
                name: 'get-doc-info',
                rules: [],
                chapter: 'Звіти',
                universal: true
            },
            {
                name: 'get-content-goods',
                rules: [],
                chapter: 'Контент',
            },
            {
                name: 'get-categories',
                rules: [],
                chapter: 'Контент',
                universal: true
            },
        ],

        show: false
    }),
    computed: {
        breadItems() {
            const items = []
            const method = this.methods.find(el => el.name == this.$route.params.method)
            items.push({
                text: 'Інтерфейси API',
                disabled: false,
                exact: true,
                to: '/api-services/'
            }, {
                text: (method.chapter ? method.chapter + ' :: ' : '') + this.$route.params.method,
                'disabled': true,
            })
            return items
        },
        universalMethod() {
            const curMethod = this.$route.params.method
            return this.methods.find(el => el.name == curMethod && el.universal)
        }
    },
    methods: {
        ...mapActions(['getContactInfo']),
        checkMethod(v) {
            this.show = false
            if (v) {
                if (this.methods.find(el => el.name == v)) {
                    this.show = true
                    this.$store.commit('setLoading', true)
                    //todo rules
                } else {
                    this.$router.push('/404')
                }
            } else {
                this.show = true
            }
        }
    },
    watch: {
        '$route.params.method'(v) {
            this.checkMethod(v)
            this.$vuetify.goTo(0)

        }
    },
    mounted() {
        this.getContactInfo()
            .then(data => {
                if (!data.isSellToEmployee) {
                    this.checkMethod(this.$route.params.method)
                } else {
                    this.$router.push('/403')
                }
            })
            .catch(error => console.log(error))
    },
}
</script>

<style>
.code textarea {
    font-family: "HelveticaNeue", "Courier New";
    font-size: 14px;
    padding: 15px;
}
</style>
