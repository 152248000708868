<template>
<v-card :class="`align-self-stretch ma-2 ` + ($vuetify.breakpoint.mdAndUp ? `pa-4 ` : `pa-0` )" :flat="$vuetify.breakpoint.mdAndUp ? true : false" :elevation="$vuetify.breakpoint.mdAndUp ? `1` : `0`" width="100%">
    <v-card-title class="px-0 justify-space-between">
        <h1>Інтерфейси API</h1>
    </v-card-title>

    <v-card-text class="px-0">
        <v-row>
            <v-col cols=12 lg=12 v-for="(chapter, id) in chapters" :key="id">
                <h2 class="second--text my-3">{{chapter}}</h2>
                <v-simple-table>
                    <thead>
                        <tr>
                            <th class="text-left" width="30%">
                                Метод
                            </th>
                            <th class="text-left" width="70%">
                                Опис
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in filteredServices(id)" :key="item.id">
                            <td><a @click="$router.push(item.to)">{{item.name}}</a></td>
                            <td v-html="item.descr"></td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-card-text>

</v-card>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'

export default {
    components: {},
    directives: {},
    data: () => ({
        chapters: {
            1: 'Авторизація',
            2: 'Прайс-листи',
            3: 'Замовлення',
            4: 'Звіти',
            5: 'Контент'
        },
        services: [{
                name: 'get-time',
                to: '/api-services/get-time',
                descr: 'Дата/час серверу В2В-портала.',
                chapter: 1
            },
            {
                name: 'get-auth-token',
                to: '/api-services/get-auth-token',
                descr: 'Метод авторизації. Необхідний для доступу до API методів.',
                chapter: 1
            },
            {
                name: 'get-price',
                to: '/api-services/get-price',
                descr: 'Доступні формати: <b>JSON</b>.<br>Дозволяє вивантажувати прайс-лист по обраній категорії каталогу товарів та комбінувати додаткові поля для отримання потрібної інформації по товарам.',
                chapter: 2
            },
            {
                name: 'get-price-ws',
                to: '/api-services/get-price-ws',
                descr: 'Доступні формати: <b>XML, Excel, JSON</b>.<br>Працює з використанням <b>WebSocket</b>.<br>Дозволяє вивантажувати прайс-лист по обраній категорії каталогу товарів та комбінувати додаткові поля для отримання потрібної інформації по товарам.',
                chapter: 2
            },
            {
                name: 'post-order',
                to: '/api-services/post-order',
                descr: 'Створення замовлення та резервування товару.',
                chapter: 3
            },
            {
                name: 'confirm-order',
                to: '/api-services/confirm-order',
                descr: 'Підтвердження відвантаження замовлення.',
                chapter: 3
            },
            {
                name: 'delete-order',
                to: '/api-services/delete-order',
                descr: 'Видалення замовлення.',
                chapter: 3
            },
            {
                name: 'get-sales-goods',
                to: '/api-services/get-sales-goods',
                descr: 'Отримання інформації про відвантаження товарів за період (аналог розділу "Історія товарного обігу")',
                chapter: 4
            },
            {
                name: 'get-sales',
                to: '/api-services/get-sales',
                descr: 'Отримання інформації про замовлення за період (аналог розділу "Історія замовлень")',
                chapter: 4
            },
            {
                name: 'get-doc-info',
                to: '/api-services/get-doc-info',
                descr: 'Отримання актуальної інформації про товарне наповнення документа відвантаження',
                chapter: 4
            },
            {
                name: 'get-order-info',
                to: '/api-services/get-order-info',
                descr: 'Отримання актуальної інформації про товарне наповнення замовлення.',
                chapter: 4
            },
            {
                name: 'get-order-sn',
                to: '/api-services/get-order-sn',
                descr: 'Отримання серійних номерів (SN) за номером замовлення.',
                chapter: 4
            },
            {
                name: 'get-doc-sn',
                to: '/api-services/get-doc-sn',
                descr: 'Отримання серійних номерів (SN) за номером документа відвантаження. <p class="primary--text">Метод виводиться з користування<b></b>. Скористайтеся новим методом <a href="/api/sales/get-doc-info"><b>get-doc-info</b></a></p>',
                chapter: 4
            },
            {
                name: 'get-categories',
                to: '/api-services/get-categories',
                descr: 'Структура товарного каталогу В2В-портала.',
                chapter: 5
            },
            {
                name: 'get-content-goods',
                to: '/api-services/get-content-goods',
                descr: 'Отримання опису, зображень, технічних характеристик товарів каталогу В2В-портала.',
                chapter: 5
            },
        ]
    }),
    props: {},
    methods: {
        filteredServices(id) {
            return this.services.filter(el => el.chapter == id)
        }
    },
    computed: {},
    created() {}
}
</script>

<style>
</style>
